const app = (function($) {
    return {
        dialogSettings: {
            autoOpen: false,
            buttons: {},
            modal: true,
            overlay: {
                opacity: 0.9,
                background: "#666666"
            },
            height: 530,
            width: 800,
            title: '',
            // show: "slow", This is causing dialog to break in jquery 1.3.2 rel, show: "slide" works but not desired
            hide: "normal",
            resizable: false,
            bgiframe: false
        },
        createDialog: function (options) {
            $('#' + options.id).dialog($.extend({}, app.dialogSettings, options.options));
            $('#' + options.id).dialog($.extend({}, app.dialogSettings, options.options));
        },
        // renders a progress indicator on the page; this function can be used
        // to indicate an ongoing progress to the user; the optional parameter "className"
        // can be used to attach an additional CSS class to the container
        showProgress: function (className) {
            let clazz = "loading";
            if (className) {
                clazz += " " + className;
            }
            return $("<div class=\"" + clazz + "\"/>").append($("<img/>").attr("src", app.URLs.loadingSmallImg));
        },
        // sub namespace app.ajax.* contains application specific ajax components
        ajax: {
            Success: "success",
            currentRequests: {}, // request cache

            // ajax request to get json response
            // @param - reqName - String - name of the request
            // @param - async - boolean - asynchronous or not
            // @param - url - String - uri for the request
            // @param - data - name/value pair data request
            // @param - callback - function - callback function to be called
            getJson: function(options) {
                let thisAjax = this;

                // do not bother if the request is already in progress
                // and let go null reqName
                if (!options.reqName || !this.currentRequests[options.reqName]) {
                    this.currentRequests[options.reqName] = true;
                    if(options.async === "undefined") {
                        options.async = true;
                    }
                    // make the server call
                    $.ajax({
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        url          : options.url,
                        cache     : true,
                        async     : options.async,
                        data     : options.data,

                        success: function(response, textStatus) {
                            thisAjax.currentRequests[options.reqName] = false;

                            if (!response || !response.Success) {
                                return;
                            }

                            if (options.hasOwnProperty('callback') && options.callback ) {
                                options.callback(response, textStatus);
                            }
                        },

                        error: function(request, textStatus, error) {
                            if (textStatus === "parsererror") {
                                alert('bad response, parser error='+request+", options.url="+options.url + " error " + error);
                            }

                            options.callback({Success: false, data:{}});
                        }
                    });
                }
            },


            // ajax request to load html response in a given container
            // @param - reqName - String - name of the request
            // @param - url - String - uri for the request
            // @param - data - name/value pair data request
            // @param - callback - function - callback function to be called
            // @param - selector - string - id of the container div/span (#mycontainer) - it must start with '#'
            load: function(options) {

                var thisAjax = this;

                // do not bother if the request is already in progress
                // and let go null reqname
                if (!options.reqName || !this.currentRequests[options.reqName]) {
                    this.currentRequests[options.reqName] = true;
                    // make the server call
                    var newOptions = {
                        dataType: "html",
                        url          : options.url,
                        cache     : true,
                        data     : options.data,

                        success: function(response, textStatus) {
                            thisAjax.currentRequests[options.reqName] = false;

                            if (options.selector) {
                                $(options.selector).html(response);
                            }

                            if (options.hasOwnProperty('callback')){
                                options.callback(response, textStatus);
                            }
                        },

                        error: function(request, textStatus, error) {
                            if (textStatus === "parsererror") {
                                alert('bad response, parser error');
                            }

                            options.callback(null, textStatus);
                        }
                    };
                    if ( options.type ) {
                        newOptions.type = options.type;
                    }
                    $.ajax( newOptions );
                }
            }
        },
        // sub namespace app.dialog.* provides convenient functions to handle dialogs
        // note, that this code relies on single dialog modals (multi dialog, e.g. modal in modal is not supported)
        dialog: {
            customOptions: {},
            // opens a dialog using the given url
            open: function (url, title, dialogClass, myOptions) {
                // create the dialog container if not present already
                if ($("#dialogcontainer").length === 0) {
                    $(document.body).append("<div id=\"dialogcontainer\"></div>");
                }

                // set a default title
                title = title || "";

                //set a default class
                dialogClass = dialogClass || "";

                // finally load the dialog, set the dialog title
                var newOptions = {
                    selector: "#dialogcontainer",
                    url: url,
                    callback: function () {
                        app.dialog.checkOpen(myOptions);
                        app.dialog.setTitle(title);
                        app.dialog.setClass(dialogClass);
                        if (myOptions) {
                            app.dialog.setSize(myOptions);
                        }
                        // wireup click event for when the user clicks on the greyed out area (outside of the popup)
                        $(".ui-widget-overlay").click(function () {
                            app.dialog.close();
                        });


                        $(".ui-dialog-titlebar-close").click(function () {
                            app.dialog.close();
                        });

                        $("body").triggerHandler("dialogOpen");


                    }
                };
                app.dialog.customOptions = myOptions;
                if (myOptions) {
                    if (myOptions.type) {
                        newOptions.type = myOptions.type;
                    }
                    if (myOptions.data) {
                        newOptions.data = myOptions.data;
                    }
                }
                app.ajax.load(newOptions);
                //alert("Length: " + $(".ui-widget-overlay").length);


            },

            // opens a dialog from existing page content
            hopUp: function (title, dialogClass, myOptions) {
                // create the dialog container if not present already
                if ($("#dialogcontainer").length === 0) {
                    $(document.body).append("<div id=\"dialogcontainer\"></div>");
                }

                // set a default title
                title = title || "";

                //set a default class
                dialogClass = dialogClass || "";

                // finally load the dialog, set the dialog title
                app.dialog.checkOpen(myOptions);
                app.dialog.setTitle(title);
                app.dialog.setClass(dialogClass);
                app.dialog.setSize(myOptions);

                // wireup click event for when the user clicks on the greyed out area (outside of the popup)
                $(".ui-widget-overlay").click(function () {
                    app.dialog.close();
                });
            },

            // initializes the dialog with common dialog actions, like closing upon canceling
            // use this function in the dialog rendering template to re-bind common actions
            // upon dialog reload
            init: function () {
                $(document).ready(function () {
                    // binds the action to all buttons defining an action through the "name" attribute
                    $("#dialogcontainer button").each(function () {
                        $(this).click(function () {
                            let action = $(this).attr("name");
                            if (action) {
                                app.dialog.submit(action);
                            }
                            return false;
                        });
                    });

                    // cancel button binding
                    $("#dialogCancelBtn").click(function () {
                        app.dialog.close();
                        return false;
                    });

                });
            },

            // sets the title of the dialog
            setTitle: function (title) {
                $("#dialogcontainer").dialog("option", "title", title);
            },

            // sets the class of the dialog
            setClass: function (className) {
                $("#dialogcontainer").addClass(className);
                $(".ui-dialog-titlebar").addClass(className);
                $("#dialogcontainer").addClass(className + "-container");
                $(".ui-dialog-titlebar").addClass(className + "-titlebar");
                this.dialogClass = className;
            },


            // checks, if the dialog is in the state "open" and sets the state if not presently set
            // this function is implicitly called by app.dialog.open(url, title) in order to initialize
            // the dialog properly; use this function to recover the "open" state of a dialog
            checkOpen: function (myOptions) {
                // 2011 Temp comment for $ UI dialog bug
                //if(!$("#dialogcontainer").dialog('isOpen'))
                //{
                if (!myOptions) {
                    myOptions = {};
                }
                $("#dialogcontainer").dialog({
                    autoOpen: false,
                    modal: true,
                    overlay: {
                        opacity: 0.9,
                        background: "#666666"
                    },
                    height: myOptions.outerHeight ? myOptions.outerHeight : 425,
                    width: myOptions.outerWidth ? myOptions.outerWidth : 460,
                    resizable: false,
                    bgiframe: false,
                    position: myOptions.position ? myOptions.position : "center"
                });

                $("#dialogcontainer").dialog("open");
                if (myOptions.uiDialogClass) {
                    $("#dialogcontainer").parents(".ui-dialog").addClass(myOptions.uiDialogClass);
                }
                //}
            },

            setSize: function (innerWidth, outerWidth, innerHeight, outerHeight) {
                if (typeof innerWidth === 'object') {
                    outerHeight = ( innerWidth.hasOwnProperty('outerHeight') ? innerWidth.outerHeight : null );
                    innerHeight = ( innerWidth.hasOwnProperty('innerHeight') ? innerWidth.innerHeight : null );
                    outerWidth = ( innerWidth.hasOwnProperty('outerWidth') ? innerWidth.outerWidth : null );
                    innerWidth = ( innerWidth.hasOwnProperty('innerWidth') ? innerWidth.innerWidth : null );
                }
                if (innerWidth !== null) {
                    $("#dialogcontainer").width(innerWidth);
                }
                if (innerHeight) {
                    $("#dialogcontainer").height(innerHeight);
                }
                // for the outer it is a little bit more sophisticated.
                if (outerWidth !== null) {
                    $("#dialogcontainer").dialog('option', 'width', outerWidth);
                }
                if (outerHeight) {
                    $("#dialogcontainer").dialog('option', 'height', outerHeight);
                }
            },

            // closes the dialog and triggers the "close" event for the dialog
            close: function () {
                // close needs to remove the classes that were added...
                // need to determine what those classes were.
                if (this.dialogClass !== "") {
                    $("#dialogcontainer").removeClass(this.dialogClass);
                    $(".ui-dialog-titlebar").removeClass(this.dialogClass);
                    $("#dialogcontainer").removeClass(this.dialogClass + "-container");
                    $(".ui-dialog-titlebar").removeClass(this.dialogClass + "-titlebar");
                    $("#dialogcontainer").parents(".ui-dialog").removeClass(this.dialogClass);
                    this.dialogClass = "";
                }
                if (app.dialog.customOptions && app.dialog.customOptions.uiDialogClass) {
                    $("#dialogcontainer").parents(".ui-dialog").removeClass(app.dialog.customOptions.uiDialogClass);
                }
                $("#dialogcontainer").dialog("close");
                $(document.body).trigger("dialogClosed");
            },

            // attaches the given callback function upon dialog "close" event
            onClose: function (callback) {
                if (typeof(callback) !== 'undefined') {
                    $(document.body).bind("dialogClosed", callback);
                }
            },

            // triggers the "apply" event for the dialog
            triggerApply: function () {
                $(document.body).trigger("dialogApplied");
            },

            // attaches the given callback function upon dialog "apply" event
            onApply: function (callback) {
                if (typeof(callback) !== 'undefined') {
                    $(document.body).bind("dialogApplied", callback);
                }
            },

            // triggers the "delete" event for the dialog
            triggerDelete: function () {
                $(document.body).trigger("dialogDeleted");
            },

            // attaches the given callback function upon dialog "delete" event
            onDelete: function (callback) {
                if (typeof(callback) !== 'undefined') {
                    $(document.body).bind("dialogDeleted", callback);
                }
            },

            // submits the dialog form with the given action
            submit: function (action) {
                // set the action
                $("#dialogcontainer form").append("<input name=\"" + action + "\" type=\"hidden\" />");

                // serialize the form and get the post url
                var post = $("#dialogcontainer form").serialize();
                var url = $("#dialogcontainer form").attr("action");

                // post the data and replace current content with response content
                $.ajax({
                    type: "POST",
                    url: url,
                    data: post,
                    dataType: "html",
                    success: function (data) {
                        $("#dialogcontainer").empty().html(data);
                    },
                    failure: function (data) {

                        alert("Server connection failed!");
                    }
                });
            }
        }
    }
})(jQuery);