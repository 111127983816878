const responsive = require('../util/responsive');
// const cssVariables = require('partials/_variables.scss');

const defaults = {
    hover: false,
    theme: 'plain',
    actionSelector: '.js-open',
    contentSelector: '.js-content',
    snapX: 'center',
    snapY: 'bottom',
    clickAnywhereToClose: true,
    hoverAnywhereToClose: true,
    closeDelay: 250, //ms
    actionZIndex: 1000,
    contentZIndex: 1025,
    backdropZIndex: 999,
    mobile: true,
    upperLimit: 9999, //reduce to set a max size that the popover works at.
    overwritePosition: false
};

let popovers = [];


class Popover {
    constructor(el, opts) {
        this.el = el;

        const tempDefaults = Object.create(defaults);
        this.opts = Object.assign(tempDefaults, opts);
        this.opened = false;
        this.closeTimeout = null;
        this.backdrop = null;

        this.actionEl = this.el.querySelector(this.opts.actionSelector);
        this.contentEl = this.el.querySelector(this.opts.contentSelector);

        this.actionEl.style.zIndex = this.opts.actionZIndex;

        this.el.classList.add('theme-'+this.opts.theme);

        this.el.classList.add('theme-mobile-'+this.opts.mobile);

        if (!this.opts.hover) {
            this._listenForClickEvents();
        } else {
            this.el.classList.add('can-hover');
            this._listenForHoverEvents();
        }

        if( this._checkContext() ) {
            this._position();
        }
    }

    toggle() {

        if( this._checkContext() ) {
            if (!this.opened) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    open() {
        this._closeAllPopovers();

        window.requestAnimationFrame(() => {

            this._position();
        this.el.classList.add('opened');
        this.opened = true;
        clearTimeout(this.closeTimeout);
        if (!this.backdrop) {
            this._createInvisibleBackdrop();
        }
    });

    }

    close(forceClose) {

        let closeDelay = forceClose ? 0 : this.opts.closeDelay;

        this.closeTimeout = setTimeout(() => {
                this.el.classList.remove('opened');
        this.opened = false;

        if (this.backdrop) {
            this._removeInstanceBackdrop();
        }
    }, closeDelay);
    }

    _listenForClickEvents() {

        this.actionEl.addEventListener('click', e => {

            if(!this.opts.mobile && responsive.isMobile() || !this._checkUpperLimit()) {
            //Do nothing.
        }
    else{
            e.preventDefault();
            this.toggle();
        }
    });
    }

    _listenForHoverEvents() {
        this.el.addEventListener('mousemove', () => this._hovering());
        this.el.addEventListener('mouseleave', () => this._unhover());
    }

    _hovering() {
        if (!this.opened) {

            if( this._checkContext() ) {
                this._position();
            }

            this.opened = true;
            this.el.classList.add('js-is-hovering');
            clearTimeout(this.closeTimeout);
        }
    }

    _unhover() {
        this.closeTimeout = setTimeout(() => {
            this.opened = false;
        this.el.classList.remove('js-is-hovering');
    }, this.opts.closeDelay);
    }

    _position() {
        if(!this.opts.overwritePosition && !!this.contentEl ) {
            this.contentEl.style.position = 'absolute';
            this.contentEl.style.zIndex = this.opts.contentZIndex;
        }
        else {
            return false;
        }

        let topCalc = 0;
        let leftCalc = 0;
        let actionElHeight = this.actionEl.clientHeight;

        if (this.opts.snapY.indexOf('bottom') !== -1) {
            topCalc = actionElHeight;
        } else if (this.opts.snapY.indexOf('center') !== -1) {
            topCalc = (actionElHeight / 2) - (this.contentEl.clientHeight / 2);
        }


        if (this.opts.snapY.indexOf('top') !== -1) {
            this.contentEl.style.bottom = `${actionElHeight}px`;
        } else {
            this.contentEl.style.top = `${topCalc}px`;
        }


        if (this.opts.snapX.indexOf('left') !== -1) {
            leftCalc = this.actionEl.offsetLeft > 0 ? this.actionEl.offsetLeft : 0;

        }

        if (this.opts.snapX.indexOf('right') !== -1) {
            this.contentEl.style.right = `0px`;
        } else {
            this.contentEl.style.left = `${leftCalc}px`;
        }


        if (this.opts.snapX.indexOf('center') !== -1) {
            this.contentEl.style.left ='50%';
            this.contentEl.style['transform'] = 'translateX(-50%)';
            this.contentEl.style['-webkit-transform'] = 'translateX(-50%)';
            this.contentEl.style['-ms-transform'] = 'translateX(-50%)';
            this.contentEl.style['-moz-transform'] = 'translateX(-50%)';
        }
    }

    _createInvisibleBackdrop() {

        if (!this.backdrop) {
            this.backdrop = document.createElement('div');
            this.backdrop.id = 'popover-invisible-backdrop';
            this.backdrop.style.zIndex = this.opts.backdropZIndex;

            document.body.appendChild(this.backdrop);
        }

        this.backdrop.style.zIndex = this.opts.backdropZIndex;
        this.backdrop.style.display = 'block';

        if (this.opts.hoverAnywhereToClose) {
            this.backdrop.addEventListener('mousemove', () => this.close());
        }

        if (this.opts.clickAnywhereToClose) {
            this.backdrop.addEventListener('click', () => this.close(true));
        }
    }

    _removeInstanceBackdrop() {
        if (this.backdrop) {
            document.body.removeChild(this.backdrop);
            this.backdrop = null;
        }
    }

    _closeAllPopovers() {
        popovers.forEach(popover => {
            if (popover.opened) {
            popover.close(true);
        }
    });
    }

    _checkContext() {

        if(this.opts.mobile === true ){
            return true;
        }
        else if( document.body.clientWidth >= parseInt(992) && this._checkUpperLimit() ){
            return true;
        }
        else{
            return false;
        }
    }

    _checkUpperLimit() {
        return this.opts.upperLimit > document.body.clientWidth;
    }
}


// window.addEventListener('scroll', e => {
//     popovers.forEach(popover => {
//         if (popover.opened) {
//             popover.close(true);
//         }
//     })
// });



/**
 * [init - Init all popovers in the selectorStr]
 * @param  {[String]} selectorStr [Selector string to find elements with.]
 * @param  {[Object]} opts        [Slider options.]
 * @return {[Array]}              [Returns a list of Sliders that were created.]
 */
function init(selectorStr, opts) {

    let newPopovers = [];

    //In the future use Array.from() here.
    let els = document.body.querySelectorAll(selectorStr);

    for(let i = 0; i < els.length; i++) {
        let popover = new Popover(els[i], opts);
        newPopovers.push(popover);
    }

    popovers = popovers.concat(newPopovers);

    return newPopovers;
}

module.exports.create = init;
