const defaults = {
    initiated: false,
    // Skip to Navigation class
    skipToNav: '.skipto-nav',
    navOffset: {
        x: 0,
        y: -125
    },
    // Set a custom class to the body when a keyboard user has been detected. This activates :focus styling.
    showFocusClass: 'show-focus',

    // Set a custom class added when element has focus. Removed when focus moves.
    onFocusClass: 'on-focus',

    /* *** Common keyboard navigation checks ***
     * Handy tool to find a `key` or `code`: https://keycode.info/
     * Numbers = `keyCode`
     * Text = `code` and `key
     * ******************************************/
    arrowKeys: [37,38,39,40, 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'],
    tabKeys: [9,'Tab'],
    // **** Intentionally left with a space. Space bar `key` is the space character
    spacebarKeys: [32, ' ', 'Space'],
    escKeys: [27, 'Escape'] // TODO: eventually we should hook this up to the lightbox `X` to close it
};
class AccessibilityEvents {

    /**
     * [constructor]
     * @param e key down event
     */
    constructor(e) {
        defaults.initiated = true;
        /* * Deprecated: `keyCode` keeping as fallback since it's still being passed on the event.
         *   Current Spec: `key` isn't all that great yet and why not throw code in for shits and giggles
         */
        this.key = e.key || e.code || e.keyCode;
        this.options = Object.assign(defaults);
        this.el = e.target;
        this.focusEnabled = false;
        this.arrowKeysUsed = this.options.arrowKeys.indexOf(this.key); // JAWS Users use arrow keys
        this.tabKeyUser = this.options.tabKeys.indexOf(this.key);
        this.enableFocusElements = this.tabKeyUser || this.arrowKeysUsed;
        this.skipToNav = document.querySelector(this.options.skipToNav);
        this.jumpToSearchLinks = this.skipToNav.querySelectorAll('a[href^="#search"]');

        this._addEventListeners();
    }


    /**
     * [_addEventListeners]
     * @private
     */
    _addEventListeners() {
        this.el.addEventListener('keydown',  this._handleKeyEvent.bind(this));
        this.el.addEventListener('keyup',    this._handleKeyEvent.bind(this));
        this.el.addEventListener('focusin',  this._handleKeyEvent.bind(this));
        this.el.addEventListener('focusout', this._handleKeyEvent.bind(this));

        // need to handle scroll to due to the nav collapse
        if(this.skipToNav.id === 'skipto-nav-main') {
            this.jumpToSearchLinks.forEach(el => {
                el.addEventListener('click', this._handleJumpToEvent.bind(this));
            });
        }
    }

    _handleJumpToEvent(e) {
        e.preventDefault();
        let hashId = e.target.hash;
        let jumpToSection = document.querySelector(hashId);

        // Just in case...
        if(jumpToSection !== null) {
            jumpToSection.scrollIntoView();
            window.scrollBy(defaults.navOffset.x, defaults.navOffset.y); // nav offset

            if(hashId.search('search') === -1) {
                jumpToSection.focus();
            } else {
                jumpToSection.querySelector('input').focus();
            }
            this.skipToNav.classList.remove(this.options.onFocusClass);
        }
    }

    _handleKeyEvent(e) {
        let el = e.target;

        if (this.enableFocusElements && e.type === 'focusin') {
            // added to body and enable skip to nav and focus
            // we only want to do this once
            if(!this.focusEnabled) {
                this.focusEnabled = true;
                this.el.classList.add(this.options.showFocusClass);
            }
            this.skipToNav.classList.add(this.options.onFocusClass);
        }

        if(!this.skipToNav.contains(document.activeElement)) {
            this.skipToNav.classList.remove(this.options.onFocusClass);
        }
    }
}

function init() {
    if(!defaults.initiated) {
        const accessibilityInitEventHandler = e => new AccessibilityEvents(e);
        document.querySelector('body').addEventListener('keydown', accessibilityInitEventHandler);

    }
}

module.exports.init = init;