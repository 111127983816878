(function(app, $) {
    'use strict';

    var defaults = {
        animation: "slide",
        touch: true
    };

    var first = true;

    function findDirection(slider) {
        var dir = 'left';

        $.each(slider.slides, function(i, item) {

            var el = $(item);
            el.removeClass('right left');
            if (i >= slider.animatingTo && dir !== 'right') {
                dir = 'right';
            } else {
                el.addClass(dir);
            }
        });
    }

    app.slider = {

        init: function(el, opts) {

            let options = $.extend({}, defaults, opts);

            if (opts && opts.addDirectionalClasses) {
                options.before = function (slider) {
                    if (opts.before) {
                        opts.before(slider);
                    }
                    findDirection(slider);
                };
            }

            options.start = function (slider) {
                $(el).addClass('is-ready');
                if (opts && opts.start) {
                    opts.start(slider);
                }
                if (opts && opts.addDirectionalClasses) {
                    findDirection(slider);
                }
            };

            $(el).flexslider(options);
        }
    };

}(window.app = window.app || {}, jQuery));