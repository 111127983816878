const cssVariables = {
    mobileNavSnapPoint: 992
};

export function isMobile() {
    return window.matchMedia(`(max-width: ${cssVariables.mobileNavSnapPoint - 1}px)`).matches;
}

export function isRetina() {
    return window.matchMedia('(min-resolution: 192dpi)').matches || window.matchMedia('(-webkit-min-device-pixel-ratio: 2)').matches;
}

const responsive = { isMobile, isRetina };
export default responsive;
