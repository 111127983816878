/**
 * https://burton.atlassian.net/wiki/display/WD/app.dialog+API
 */

(function(app, $) {
    'use strict';

    var dialogElement = null;

    var defaults = {
        speed: 450,
        title: '',
        onApply: '',
        maxWidth: '100%',
        maxHeight: '100%',
        scrolling: true,
        fixed: true,
        onOpen: function() {
            executeCallbacks(callbacks.onOpened);
        },
        onClosed: function() {
            executeCallbacks(callbacks.onClosed);
            clearCallbacks();
        },
        onComplete: function() { // When the colorbox opening sequence is complete.
            executeCallbacks(callbacks.onComplete);
        }
    };

    ////////////////////
    // Public Methods //
    ////////////////////

    app.dialog = {

        open: open,
        init: initInteractions, //Only used in a few checkout areas, used to rebind stuff?
        setSize: resize,
        close: close,
        submit: submit,
        resize: resize,
        onOpen: function(callBack) {
            addCallback(callbacks.onOpened, callBack);
        },
        onClose: function(callBack) {
            addCallback(callbacks.onClosed, callBack);
        },
        onComplete: function(callBack) {
            addCallback(callbacks.onComplete, callBack);
        },
        unbindClose: function(callBack) {
            removeCallback(callbacks.onClosed, callBack);
        }
    };

    function open(opts) {
        var options = $.extend({}, defaults, opts);

        options.width = opts.outerWidth ? opts.outerWidth : opts.width;
        options.height = opts.outerHeight ? opts.outerHeight : opts.height;

        $.colorbox(options);

        dialogElement = $('#cboxContent');
        dialogElement.addClass('app-dialog');
        initInteractions();
    }

    function initInteractions() {

        dialogElement.find('button').on('click', function(e) {
            var btn = $(e.currentTarget);
            var action = btn.attr('name');
            if (action) {
                app.dialog.submit(action);
                e.preventDefault();
            }
        });

        $('#dialogCancelBtn').on('click', app.dialog.close);
    }

    function submit(action) {

        var form = dialogElement.find('form');
        form.append('<input name=\"' + action + '\" type=\"hidden\" />');

        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'html',
            success: function(data) {
                dialogElement.empty().html(data);
            },
            failure: function() {
               //log('Server connection failed!');
            }
        });
    }

    function close() {
        $.colorbox.close();
    }

    function resize(opts) {
        $.colorbox.resize(opts);
    }



    ///////////////
    // Callbacks //
    ///////////////

    var callbacks = {
        onOpened: [],
        onClosed: [],
        onComplete: []
    };

    function clearCallbacks() {
        for (var i = 0; i < callbacks.length; i++) {
            callbacks[i] = [];
        }
    }

    function addCallback(list, callBack) {
        list.push(callBack);
    }

    function removeCallback(list, callBack) {
        var functStr = callBack.toString();
        for (var i = 0; i < list.length; i++) {
            var itemStr = list[i].toString();
            if (functStr === itemStr) {
                list.splice(i, 1);
            }
        }
    }

    /**
     * [executeCallbacks - Executes all functions in the queue when a callback is dispatched from colorbox.]
     */
    function executeCallbacks(list) {
        for (var i = 0; i < list.length; i++) {
            var funct = list[i];
            if (typeof funct === 'function') {
                funct();
            }
        }
    }

}(window.app = window.app || {}, jQuery));



///////////////////
// jQuery Bridge //
///////////////////

(function(app, $) {

    $(function() {
        $('[data-dialog]').each(function(i, el) {
            $(el).on('click', function(e) {
                e.preventDefault();
                var btn = $(e.currentTarget);
                app.dialog.open({
                    href: btn.attr('href'),
                    iframe:true,
                    width: '50%',
                    height:'50%'
                });
            });
        });
    })

}(window.app = window.app || {}, jQuery));

