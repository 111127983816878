const defaults = {

    classWhenSnapped: 'el-is-snapped',
    triggerY: 0, // At what offset should the element get snapped? Can be an integer or a function that returns an integer.
    ocassionallyRecalcTrigger: false, //Should we recheck the triggerY point occasionally? (Requires it to be a function.)
    recalcInterval: 10,
    on: {
        init: [],
        snap: [],
        unsnap: []
    }
};

class Snappable {
    constructor(el, opts) {
        this.el = el;
        this.snapped = false;

        const tempDefaults = Object.create(defaults);
        this.opts = Object.assign(tempDefaults, opts);

        if (typeof this.opts.triggerY === 'function') {
            this.triggerY = this.opts.triggerY();
        } else {
            this.triggerY = this.opts.triggerY;
        }

        if (this.opts.ocassionallyRecalcTrigger) {
            this.recalcInt = 0;
        }

        this._buildCallbacks();

        addGlobalCallback(y => this.checkPosition(y));
        handleGlobalCallbacks(); //Check scrolling for the first time.


        this._executeCallback('init');
    }

    checkPosition(currentY) {

        if (this.opts.ocassionallyRecalcTrigger && this.recalcInt++ > this.opts.recalcInterval) {
            this.recalcInt = 0;
            this.triggerY = this.opts.triggerY();
        }

        if (currentY >= this.triggerY) {
            if (!this.snapped) {
                this.snapped = true;
                this._executeCallback('snap');
                this.el.classList.add(this.opts.classWhenSnapped);
            }
        } else {
            if (this.snapped) {
                this.snapped = false;
                this._executeCallback('unsnap');
                this.el.classList.remove(this.opts.classWhenSnapped);
            }
        }
    }

    /**
     * [buildCallbacks - Map the this.on.* object to this.callbacks.*, and elegantly handle inputs that are either a single function or an array. ]
     */
    _buildCallbacks() {
        this.callbacks = {};
        for (let type in this.opts.on) {
            let oldList = this.opts.on[type];
            let cbList = this.callbacks[type] = [];
            if (typeof oldList === 'function') {
                cbList.push(oldList);
            } else if (oldList.length > 0) {
                this.callbacks[type] = cbList.concat(oldList);
            }
        }
        this.opts.on = null;
    }

    _executeCallback(type) {
        let list = this.callbacks[type];
        for (let i = 0; list && i < list.length; i++) {
            let cb = list[i];
            if (typeof cb === 'function') {
                cb();
            }
        }
    }

}

let snappables = [];
let callbacks = [];
let callbacksLength = 0;

function addGlobalCallback(cb) {
    callbacks.push(cb);
    callbacksLength = callbacks.length;
}

function handleGlobalCallbacks() {
    let currentY = window.pageYOffset;
    for (let i = 0; i < callbacksLength; i++) {
        callbacks[i](currentY);
    }
}

window.addEventListener('scroll', () => {
    window.requestAnimationFrame(handleGlobalCallbacks);
}, Modernizr.passiveeventlisteners ? {passive:true} : false);

/**
 * [init - Init all sliders in the selectorStr]
 * @param  {[String]} selectorStr [Selector string to find elements with.]
 * @param  {[Object]} opts        [options.]
 * @return {[Array]}              [Returns a list of elements that were created.]
 */
function init(selectorStr, opts) {

    //In the future use Array.from() here.
    let els = document.body.querySelectorAll(selectorStr);
    let newSnappables = [];

    for(let i = 0; i < els.length; i++) {
        let snappable = new Snappable(els[i], opts);
        newSnappables.push(snappable);
    }
    snappables = snappables.concat(newSnappables);

    return newSnappables;
}

module.exports.create = init;