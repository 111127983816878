import Hammer from 'hammerjs';

import popover from './../../components/popover';
import scrollToPosition from './../../components/scroll-to-position';
import snappable from './../../components/snappable';
import responsive from './../../util/responsive';
import slider from './slider';


function init() {
    initMinorHeaderPromotion();

    if (document.querySelector('.js-global-header-full')) {
        let globalHeader = $('.global-header');
        initPlugins();
        initMobileNavExpander();
        initFancyNavTransitions();

        $('.js-toggles-mobile-nav').on('click', e => {
            e.preventDefault();
            globalHeader.toggleClass('mobile-opened');
            globalHeader.addClass('should-animate');
            document.body.classList.add('mobile-nav-opened');
        });

        $('.js-closes-mobile-nav').on('click', e => {
            e.preventDefault();
            closeMobileHeader();
        });

        globalHeader.on('transitionend', e => {
            globalHeader.removeClass('should-animate');
        });

        globalHeader.on('header.close',closeMobileHeader);

    }

}

function closeMobileHeader() {
    let globalHeader = $('.global-header');
    globalHeader.removeClass('mobile-opened');
    globalHeader.addClass('should-animate');
    document.body.classList.remove('mobile-nav-opened');
}


function initMobileNavExpander() {
    let isAnimating = false;
    let flags = document.querySelectorAll('.global-header .icon-flag-lazy');

    function openDropdown(el) {
        let targetHeight = el.outerHeight();
        el.addClass('expanded');
        el.css('height', 0);

        el.animate({
            height: targetHeight
        }, 250, () => {
            el.css('height', 'auto');
            isAnimating = false;
        });

    }

    function closeDropdown(el) {

        el.animate({
            height: 0
        }, 250, () => {
            el.removeClass('expanded');
            el.css('height', 'auto');
            isAnimating = false;
        });

    }

    function closeAllDropdowns() {
        let header = $('.global-header .global-nav-container');
        header.find('.show-close-icon').removeClass('show-close-icon');
        header.find('.js-will-expand.expanded').each((idx, el) => closeDropdown($(el)));
    }

    function triggerCpsAppEvent(href) {
        $('#js-react-cps-app-root').trigger('__CPS_APP_ROUTE',{href: href});
        $('.global-header').trigger('requestclose');
        closeAllDropdowns();
        scrollToPosition.to(0);
    }

    /**
     * Enable the dropdowns to be opened and closed. If the user clicks on the text in the dropdown when it's opened,
     * take them to the URL.
     */

    function handleTap(e) {
        let sourceEl = $(e.target);
        let targetEl = sourceEl.closest('.js-mobile-expander');
        let isCpsApp = document.querySelector('#js-react-cps-app-root') !== null;
        let linkEl;

        if(typeof e.target?.dataset?.cpsAppLinkHeader !== 'undefined') { // If data-cps-app-link-header attribute is on the element
            linkEl = e.target;
        } else if ($(e.target).closest('[data-cps-app-link-header]').length) {
            linkEl = $(e.target).closest('[data-cps-app-link-header]')[0];
        }

        if (targetEl.length && responsive.isMobile()) {

            if (!isAnimating) {
                isAnimating = true;
                let listEl = targetEl.next('.js-will-expand');
                let isExpanded = listEl.hasClass('expanded');

                if (!isExpanded) {
                    e.preventDefault();
                    let commonParent = listEl.parents('.js-expand-wrapper').siblings('.js-expand-wrapper');
                    openDropdown(listEl);
                    closeDropdown(commonParent.find('.expanded'));
                    commonParent.find('.show-close-icon').removeClass('show-close-icon');
                    targetEl.addClass('show-close-icon');
                } else if (!$(e.target).hasClass('js-mobile-btn-hit-area')) { // If not a click on the link text
                    e.preventDefault();
                    closeDropdown(listEl);
                    targetEl.removeClass('show-close-icon');
                } else {

                    if (isCpsApp && linkEl) {
                        e.preventDefault();
                        closeMobileHeader();
                        triggerCpsAppEvent(linkEl.href);
                    } else {
                        closeMobileHeader();
                    }

                    isAnimating = false;

                }

            } else {
                e.preventDefault();
            }

        } else if (isCpsApp && linkEl) {
            console.log('navigating to ' + linkEl.href);
            e.preventDefault();
            closeMobileHeader();
            triggerCpsAppEvent(linkEl.href);
        }

        // Else, go to the link

    }

    let headerExpanderEl = document.querySelector('.global-header .global-nav-container');
    headerExpanderEl.addEventListener('click', handleTap);

    let footerExpanderEl = document.querySelector('.global-footer');
    let footerExpanderHammer = new Hammer(footerExpanderEl, {domEvents:true});
    footerExpanderHammer.on('tap', handleTap );

}

function initFancyNavTransitions() {
    let globalHeaderEl = $('.global-header');
    let globalNavigationEl = globalHeaderEl.find('.global-navigation');
    let categoryNavColumnEl = globalNavigationEl.find('.category-nav-column');
    let holdAfterRolloffTime = 100;
    let timeUntilOpen = 100;
    let openTimeout = null;
    let resetTimeout = null;
    let opened = false;

    // Listen to this event that's dispatched when the Category Page is active and wants to close the nav during a quick category switch.
    globalHeaderEl.on('requestclose', e => closeNav());
    globalNavigationEl.find('.section-wrapper').addClass('fancy-transitions').removeClass('basic-transitions');

    function closeNav(){
        clearTimeout(resetTimeout);
        clearTimeout(openTimeout);
        openTimeout = null;
        globalNavigationEl.find('.section-wrapper').removeClass('is-hovering');
        $('.desktop-touch-nav-blackout').removeClass('opened');
        opened = false;
    }

    function openNav(currentTarget, showBlackout = false) {
        let currentTargetEl = $(currentTarget);
        globalNavigationEl.find('.section-wrapper').removeClass('is-hovering');
        currentTargetEl.addClass('is-hovering');

        if (showBlackout) {
            $('.desktop-touch-nav-blackout').addClass('opened');
        }

        if (!currentTargetEl.hasClass('was-open')) {

            currentTargetEl.find('.product-image').each(function(i, val) {
                let el = $(val);
                el.prop('src', el.attr('data-src'));
            });

            currentTargetEl.addClass('was-open');

        }

        opened = true;

    }

    categoryNavColumnEl.on('mouseleave', '.section-wrapper', e => {
        clearTimeout(openTimeout);
        resetTimeout = setTimeout(() => {
            closeNav();
        }, holdAfterRolloffTime);
    });

    categoryNavColumnEl.on('mouseenter touchstart', '.section-wrapper', navHovered);

    // let headerNavBtn = document.querySelector('.global-navigation .category-nav-column');
    // let headerNavHammer = new Hammer(headerNavBtn, {domEvents:true});
    // headerNavHammer.on('tap', handleNavTap );
    categoryNavColumnEl.on('touchstart', e => handleNavTap(e));

    $('.js-closes-desktop-nav').on('click', e => {
        e.preventDefault();
        closeNav();
    });

    function handleNavTap(e) {

        if (!responsive.isMobile()) {
            let sourceEl = $(e.target);
            let targetEl = sourceEl.closest('.section-wrapper');

            // Either an actual link, or element within link that has bubbling event (like spans in Anon nav links)
            let isLink = e.target.tagName.toLowerCase() === 'a' || sourceEl.closest('a').length;

            // TODO: Add exception for promo slider navigation

            if (!$(targetEl).hasClass('is-hovering') && targetEl.hasClass('section-wrapper')) {

                e.preventDefault();
                let hasDropdownNav = $(targetEl).hasClass('js-expand-wrapper');
                openNav(targetEl, hasDropdownNav); // Only show blackout if there's a dropdown nav

            } else if (!isLink) { // Only close nav if there's no link to navigate to
                closeNav();
            }

        }

    }

    function navHovered(e) {

        if (!responsive.isMobile() && e.type !== 'touchstart') {
            let currentTarget = e.currentTarget;
            clearTimeout(resetTimeout);
            clearTimeout(openTimeout);

            if (!responsive.isMobile()) {
                if (opened) {
                    openNav(currentTarget);
                } else {
                    openTimeout = setTimeout(()=>{
                        openNav(currentTarget);
                    },timeUntilOpen);
                }
            } else {
                openNav(currentTarget);
            }
        }

    }

}


function initPlugins() {

    popover.create('.global-header .js-popover-hover-bottom', {
        theme:'header-blogs',
        // upperLimit: 1280,
        hover: false,
        snapX: 'left',
        snapY: 'bottom',
        mobile: false,
        xxl: false,
        actionZIndex: 2103,
        contentZIndex: 2102,
        backdropZIndex: 2000
    });

    popover.create('.global-header .js-popover-bottom-right', {
        theme:'minimal',
        hover: false,
        snapX: 'right',
        snapY: 'bottom',
        mobile: false,
        actionZIndex: 2103,
        contentZIndex: 2102,
        backdropZIndex: 2000
    });

    if (!$('body').hasClass('no-snappable-nav')) {

        snappable.create('.global-header .js-will-stick-to-top', {
            ocassionallyRecalcTrigger: true,
            triggerY: function () {
                return document.querySelector('.sticky-wrap').offsetTop;
            },
            on: {
                snap: () => {
                    document.querySelector('.main-page-wrap').classList.add('nav-is-snapped');
                    //document.querySelector('.micro-search-app-wrap').classList.add('nav-is-snapped');
                },
                unsnap: () => {
                    document.querySelector('.main-page-wrap').classList.remove('nav-is-snapped');
                    //document.querySelector('.micro-search-app-wrap').classList.remove('nav-is-snapped');
                }
            }
        });
    }
}

function initMinorHeaderPromotion() {

    //Add a body class so our CSS can conditionally do stuff when the promotion is active.
    if (document.querySelectorAll('.js-minor-header-promotion.js-sticks-to-nav-mobile').length > 0) {
        document.body.classList.add('has-minor-header-promotion');
    }

}


function sortRegionSelectorCountries(selectorStr) {
    let countriesList = $(selectorStr);
    let sortedList = countriesList.children('li').sort((a, b) => {
        let liA = $(a).text().toUpperCase();
        let liB = $(b).text().toUpperCase();
        return liA < liB ? -1 : liA > liB ? 1 : 0;
    });

    countriesList.append(sortedList);

}

const Header = {init, sortRegionSelectorCountries};
export default Header;
