/**
 * Super fast way to scroll to a certain page position using requestanimationframe.
 */
let scrollInterval = false;

// get the element offset to the document vs. the viewport
function offset(el) {
    if(!el) return false;
    // James says, Make sure you THROTTLE THIS!!!
    let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function to(tY = 0, callback){

    let currentPosition = window.pageYOffset;
    let stepY = 0;

    function step() {
        stepY = currentPosition + ( (tY - currentPosition) /5 );
        window.scrollTo(0,stepY);
        currentPosition = stepY;
        if(stepY < tY - 1 || stepY > tY + 1) {
            scrollInterval = window.requestAnimationFrame(step);
        }
        else {
            if(typeof callback === 'function') {
                callback();
            }
        }
    }

    scrollInterval = window.requestAnimationFrame(step);
}

function within(tY = 0, container, callback){

    let currentPosition = container.scrollTop;
    let stepY = 0;

    function step() {
        stepY = currentPosition + ( (tY - currentPosition) /5 );
        container.scrollTop = stepY;
        currentPosition = stepY;
        if(stepY < tY - 1 || stepY > tY + 1) {
            scrollInterval = window.requestAnimationFrame(step);
        }
        else {
            if(typeof callback === 'function') {
                callback();
            }
        }
    }

    scrollInterval = window.requestAnimationFrame(step);
}

function stop() {
    window.cancelAnimationFrame(scrollInterval);
}

const scrollToPosition = { offset, to, within, stop };
export default scrollToPosition;
