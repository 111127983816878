import slider from './modules/ui/slider';
/**
 * Featured posts show more ajax request/load
 */

let featuredPosts = featuredPosts || {},
    $featuredPosts = $('.featured-posts'),
    $loadMoreBtn = $('.load-more-btn'),
    $loadMorePostEndpoint = $featuredPosts.data('endpoint');

featuredPosts.constants = {
    initPageUrl: $loadMorePostEndpoint
};

featuredPosts.init = function () {
    $('.load-more-btn').on('click', featuredPosts.attemptLoad);
};

featuredPosts.load = function (url) {
    $.getJSON(url, function (data) {

        let items = [],
            pubDate = '';

        $.each(data.posts, function (i, item) {
            pubDate = item.pub_date ? '<time datetime="' + item.pub_date + '" pubdate>' + item.pub_date + '</time>' : '';

            items.push('<div class=\'post animate\'><a href=\'' + item.post_url + '\'><figure><div class=\'media-object\'><img src=\'' + item.image_url + '\' /></div><figcaption><h2>' + item.title + '</h2>' + pubDate + '</figcaption></figure></a></div>');

        });

        $featuredPosts.append(items.join(''));
        $loadMoreBtn.attr('data-next-page', data.next_page);

        if (data.next_page == 0) {
            $loadMoreBtn.addClass('inactive');
            $featuredPosts.removeClass('loading');
        }

    });
};

featuredPosts.attemptLoad = function (e) {
    let nextPageNum = $loadMoreBtn.attr('data-next-page');
    $featuredPosts.removeClass('loading');
    e.preventDefault();

    if (nextPageNum != '0') {
        let nextPageUrl = featuredPosts.constants.initPageUrl + '?page=' + nextPageNum;

        $featuredPosts.addClass('loading');
        $loadMoreBtn.removeClass('inactive');
        featuredPosts.load(nextPageUrl);
    }

};

featuredPosts.init();


//})(jQuery);

/**
 * Blog post page slideshow
 */

//(function ($) {
//
if (document.querySelectorAll('.post-slider').length > -1) {
    app.slider.init('.post-slider', {
        initDelay: 4000,
        slideshowSpeed: 4000,
        animationSpeed: 1000
    });
}

//})(jQuery);