import Header from './modules/ui/header';
const AccessibilityEvents = require('./accessibility-events');

/**
 * Init all the things
 */

Header.init();
AccessibilityEvents.init();

// Mobile blogs search toggle
$('.js-opens-mobile-search').on('touchend click',e => {
    e.preventDefault();
    e.stopPropagation();
    // $('.js-mobile-search-logo').toggleClass('show');
    $('.js-mobile-blog-search').toggleClass('show');

    $('.js-opens-mobile-search').toggleClass('close');
    $('.js-search-input').trigger('focus');
});